<template>
  <div :class="$style.container">
    <form @submit.prevent="submitForm">
      <h1>Cоздать менеджера</h1>
      <ImageBulkUploader
        v-model="form.image"
        :text="'Загрузить фото'"
        :class="$style.imageUploader"
      />
      <div :class="$style.photo" v-if="form.image">
        <img :src="s3_link + form.image[0]" alt="Загрузить фото" />
      </div>
      <div :class="$style.photo" v-else>
        <img
          src="/img/createManagers/default-avatar.webp"
          alt="Загрузить фото"
        />
      </div>

      <Input
        type="text"
        placeholder="Имя"
        v-model="form.name"
        :error="errors.name"
      />
      <Input placeholder="Email" v-model="form.email" :error="errors.email" />
      <Input placeholder="Телефон" v-model="form.phone" :error="errors.phone" />
      <Input
        type="password"
        placeholder="Пароль"
        v-model="form.password"
        :error="errors.password"
      />
      <Input
        type="password"
        placeholder="Повторите пароль"
        v-model="form.repeatPassword"
        :error="errors.repeatPassword"
      />
      <Input
        placeholder="Ссылка на чат с Менеджером"
        v-model="form.messengerLink"
      />
      <Input placeholder="Доп. номер телефона" v-model="form.additionalPhone" />
      <div :class="$style.divider"></div>
      <Input
        placeholder="Manager tag"
        v-model="form.managerTag"
        :error="errors.managerTag"
      />
      <div v-if="form.managerTag.length">
        <Checkbox :class="$style.checkbox" v-model="form.managerTagEnabled">
          Тег включён
        </Checkbox>
      </div>
      <el-select v-model="role" placeholder="Выберите роль">
        <el-option
          v-for="item in Object.keys($options.ROLES)"
          :key="item"
          :label="$options.TRANSLATED_ROLES[item]"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="additionalRoles"
        multiple
        placeholder="Выберите доп. роль"
      >
        <el-option
          v-for="item in Object.keys($options.ADDITIONAL_ROLES)"
          :key="item"
          :label="$options.TRANSLATED_ADDITIONAL_ROLES[item]"
          :value="item"
        >
        </el-option>
      </el-select>
      <div v-if="$store.state.auth.manager.isAdmin">
        <div :class="$style.divider"></div>
        <div>
          <Checkbox :class="$style.checkbox" v-model="isAdmin">
            Админ
          </Checkbox>
        </div>
        <div>
          <Checkbox :class="$style.checkbox" v-model="isDefault">
            Менеджер по умолчанию
          </Checkbox>
        </div>
        <div>
          <Checkbox :class="$style.checkbox" v-model="isLandings">
            Доступ к AddLandings
          </Checkbox>
        </div>
        <div>
          <Checkbox :class="$style.checkbox" v-model="isSellers">
            Доступ к AddSellers
          </Checkbox>
        </div>
        <div>
          <Checkbox
            :class="$style.checkbox"
            v-model="form.availableUponRegistration"
          >
            Доступен при регистрации
          </Checkbox>
        </div>
        <div>
          <Checkbox :class="$style.checkbox" v-model="isAddwine">
            Доступ к AddWine
          </Checkbox>
        </div>
      </div>
      <div :class="$style.action">
        <Button :type="'submit'">Создать аккаунт</Button>
        <div v-if="error" :class="$style.error">
          {{ error }}
        </div>
      </div>
      <Loader :isLoading="isLoading" />
    </form>
  </div>
</template>

<script>
import regexp from '@/helpers/regexp.js'
import ImageBulkUploader from '@/components/moleculs/ImageBulkUploader'
import Button from '@/components/atoms/Button.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import Input from '@/components/atoms/Input.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'
import delivery from '@/delivery'
import {
  ROLES,
  TRANSLATED_ROLES,
  ADDITIONAL_ROLES,
  TRANSLATED_ADDITIONAL_ROLES,
} from '@/constants/roles'

export default {
  ROLES,
  TRANSLATED_ROLES,
  ADDITIONAL_ROLES,
  TRANSLATED_ADDITIONAL_ROLES,
  components: {
    ImageBulkUploader,
    Button,
    Loader,
    Input,
    Checkbox,
  },
  computed: {
    s3_link() {
      return process.env.VUE_APP_S3_URL
    },
  },
  data() {
    return {
      form: {
        image: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        repeatPassword: '',
        messengerLink: '',
        additionalPhone: '',
        managerTag: '',
        managerTagEnabled: false,
        availableUponRegistration: false,
      },
      isAdmin: false,
      isDefault: false,
      isLandings: false,
      isSellers: true,
      isAddwine: false,
      role: null,
      additionalRoles: null,
      errors: {
        name: '',
        email: '',
        password: '',
        phone: '',
        managerTag: '',
      },
      error: '',
      isLoading: false,
    }
  },
  methods: {
    clearErros() {
      this.error = ''
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },
    resetForm() {
      this.isAdmin = false
      this.isDefault = false
      for (const key in this.form) {
        this.form[key] = ''
      }
    },
    isFormValid() {
      let isValid = true
      this.clearErros()

      if (!this.form.name) {
        this.errors.name = 'Введите имя'
        isValid = false
      }

      if (!this.form.email) {
        isValid = false
        this.errors.email = 'заполните это поле'
      } else if (!regexp.email.test(this.form.email)) {
        isValid = false
        this.errors.email = 'некорректный e-mail'
      }
      if (
        this.form.managerTag &&
        !regexp.managerTag.test(this.form.managerTag)
      ) {
        isValid = false
        this.errors.managerTag = 'некорректный тег менеджера'
      }

      if (!this.form.password) {
        this.errors.password = 'Введите пароль'
        isValid = false
      } else if (this.form.password !== this.form.repeatPassword) {
        this.errors.repeatPassword = 'Пароли не совпадают'
        isValid = false
      }

      if (!this.form.phone) {
        this.errors.phone = 'Добавьте номер телефона'
        isValid = false
      }

      if (!isValid) {
        this.error = 'Заполните обязательные поля или введите корркетные данные'
      }

      return isValid
    },
    async submitForm() {
      this.clearErros()
      if (this.isFormValid()) {
        this.isLoading = true
        if (!this.form.managerTag.length) {
          this.form.managerTagEnabled = false
        }
        const result = await delivery.AddwineCore.ManagersActions.create({
          ...this.form,
          messengerLink: this.form.messengerLink,
          image: this.form.image[0],
          isAdmin: this.isAdmin,
          isDefault: this.isDefault,
          role: this.role ?? ROLES.MANAGER,
          additionalRoles: this.additionalRoles ?? [],
          projectPermission: this.projectPermission(),
        })
        if (result.error) {
          this.error = result.error
        } else {
          this.resetForm()
          alert('Менеджер успешно создан')
        }

        this.isLoading = false
      }
    },
    projectPermission() {
      let permission = ''
      if (this.isLandings) permission = permission + 'landings,'
      if (this.isSellers) permission = permission + 'sellers,'
      if (this.isAddwine) permission = permission + 'addwine,'
      return permission
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.container {
  max-width: 40.5rem;
  margin: 3rem auto;
  padding: 1rem;
  background: $white;
  form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    label {
      text-align: left;
    }
  }
  .checkbox {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    margin: 1rem 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: $light-gray;
    text-align: center;
    cursor: pointer;
    z-index: 7;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $dark-gray;
    }
    div:nth-child(1) {
      order: 2;
    }
    div:nth-child(2) {
      order: 1;
    }
  }
  .imageUploader {
    text-align: center;
    padding: 0.5rem;
  }
  .photo {
    width: 15rem;
    margin: 0 auto;
    height: 15rem;
    border-radius: 50%;
    @include baseShadow;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .error {
    @include errorInscription;
    margin-top: 0.4rem;
  }
}
</style>
